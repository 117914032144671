$(document).ready(function() {
  $("#itemGallery").lightGallery({
    selector: '.product-item-gallery',
    useLeft: true,
    thumbnail: true,
    animateThumb: false,
    showThumbByDefault: false,
    zoom: false,
  });

  if ($(window).innerWidth() > 998) {

    $('.category-row').each(function(indx, element){

      var current_el_right_side = $(element).find('.category-row__right');
      var current_el_left_side = $(element).find('.category-row__left');
      var current_el_links = $(element).find('.category-info__bottom');
  
      var number_current_el_right_side = $(current_el_right_side).innerHeight();
      var number_current_el_left_side = $(current_el_left_side).innerHeight();

      var number_current_el_links = $(current_el_links).innerHeight();
  
      if ((number_current_el_left_side + number_current_el_links) > number_current_el_right_side) {
        $(current_el_right_side).css('minHeight', number_current_el_left_side + ($('.category-info__bottom').innerHeight()));
      }
   
    });


  }


});


