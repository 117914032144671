$(function() {

	scrollFunction();

	// Side Menu Control
	var myDir = ($("html").attr('dir') == 'rtl' ? 'right' : 'left');
	var mySlidebars = new $.slidebars();
	$('.mobile-menu-btn').on('click', function() {
		mySlidebars.slidebars.toggle(myDir);
	});

	$(window).on("scroll", scrollFunction);

	function scrollFunction() {
		if ($(window).scrollTop() > 20) {
			$(".main-header").addClass("scrolling");
		} else {
			$(".main-header").removeClass("scrolling");
		}
  }
  
  $('.drop-menu__main li').on('mouseenter', function(){
    $(this).closest('.drop-menu__main').find('li.drop-menu__li-first').removeClass('drop-menu__li-first');
  });
  $('.drop-menu').on('mouseleave', function(){
    $(this).find('.drop-menu__main > li:first-child').addClass('drop-menu__li-first');
  });

  // $(".drop-menu__main li").hover(function(){
  //   $(this).closest('.drop-menu__main').find('li.drop-menu__li-first').removeClass('drop-menu__li-first');
  //   }, function(){
  //   $(this).closest('.drop-menu__main').find('li:first-child').addClass('drop-menu__li-first');
  // });

	// animate scroll to link
	$(document).on('click', 'a[href^="#"]', function(event) {
		event.preventDefault();
		$('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top
		}, 500);
	});

});
