$(function() {

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('.hp-news__slider').slick({
		dots: false,
		infinite: true,
		speed: 1000,
		arrows: true,
		fade: true,
		rtl: dirRtlFlag,
		autoplay: true,
		autoplaySpeed: 10000,
		cssEase: 'linear',
		adaptiveHeight: true,
		pauseOnHover: false
	});

	$('.hp-boxes').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		infinite: true,
		speed: 1000,
		arrows: true,
		rtl: dirRtlFlag,
		autoplay: false,
		autoplaySpeed: 5000,
		cssEase: 'linear',
		pauseOnHover: false,
		responsive: [{
				breakpoint: 993,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 766,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					adaptiveHeight: true,
				}
			},
		]
	});

});
